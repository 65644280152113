import React, { useRef } from "react";
import "../styles/Response.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Response = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section className="response" id="response">
      <img className="response__border" src={process.env.PUBLIC_URL + "/bgs/responseBg.svg"} alt="" />
      <div className="response__content wrapper">
        <h3>Ваші Відгуки</h3>
        <div className="response__list">
          <Swiper
            autoHeight={true}
            freeMode={true}
            grabCursor={true}
            resistanceRatio={0}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              370: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1.5,
              },
              768: {
                slidesPerView: 1.5,
              },
              992: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 2,
              },
              1440: {
                slidesPerView: 2.5,
              },
            }}
            loop={true}
            modules={[Autoplay, Navigation]}
            className="response__swiper"
            navigation={{
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {responseData.map((el, index) => (
              <SwiperSlide className="response__slide" key={index}>
                <div className="response__card">
                  <div className="response__star">
                    {Array.from({ length: el.starRate }, (_, i) => (
                      <img key={i} src={process.env.PUBLIC_URL + "/emoji/Star.png"} alt={`star-${i}`} />
                    ))}
                  </div>
                  <div className="response__desc">
                    <p>{el.desc}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="response__nav">
            <div className="response__btns">
              <button ref={navigationPrevRef}>
                <svg width="123" height="36" viewBox="0 0 123 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M120.954 18.1977L2.12384 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                  <path d="M23.4332 34.382L2.12378 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                  <path d="M23.4332 2.01337L2.12378 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                </svg>
              </button>
              <button ref={navigationNextRef}>
                <svg width="123" height="36" viewBox="0 0 123 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.88794 18.1977L120.718 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                  <path d="M99.4082 2.01337L120.718 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                  <path d="M99.4082 34.382L120.718 18.1977" stroke="#8B9299" strokeWidth="3" strokeLinecap="round" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Response;

const responseData = [
  {
    id: 0,
    starRate: 5,
    desc: "Наш син просто в захваті від занять з ментальної арифметики в Quick School. За короткий час він навчився швидко рахувати в голові і робить це із задоволенням. Викладачі дуже професійні та знаходять підхід до кожної дитини. Дуже Вам Дякую!",
  },
  {
    id: 1,
    starRate: 5,
    desc: "Вітаю) Хочу виразити щиро подяку Вашій школі. Завдяки курсам англійської мови наша дитина не тільки покращила свої знання, а й нарешті полюбила англійську. Менеджер та викладачі дуже крууууті. Дякую)",
  },
  {
    id: 2,
    starRate: 5,
    desc: "Після курсу швидкочитання в Quick School наш син став читати у два рази швидше! Він не тільки краще запам'ятовує прочитане, але й навчився ефективно працювати з текстами на уроках. Викладачі роблять справжні дива!!!",
  },
  {
    id: 3,
    starRate: 5,
    desc: "Курси української мови допомогли моїй доньці значно покращити знання рідної мови. Викладачі вміло поєднують граматику, читання і творчі завдання. Щиро дякую за чудовий підхід! Донька вже з нетерпінням чекає наступного заняття)",
  },
  {
    id: 4,
    starRate: 5,
    desc: "Добрий день. Школа Quick School допомогла моїй доньці подолати труднощі з математикою та українською мовою. Заняття цікаві та результативні, донька із задоволенням виконує всі завдання.",
  },
];
