import React, { useState, useEffect } from "react";
import "../styles/Header.scss";

const Header = ({ openModal }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      <div className="header__body">
        <div className="header__logo">
          <img src={process.env.PUBLIC_URL + "logo.svg"} alt="" />
        </div>
        <div className={`header__burger ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`header__nav ${isMenuOpen ? "active" : ""}`}>
          <a href="#about">Про школу</a>
          <a href="#special">Особливості школи</a>
          <a href="#classes">Напрямки</a>
          <a href="#response">Відгуки</a>
          <a href="#footer">Контакти</a>
        </div>
        <div className="header__btn">
          <button className="btn" onClick={openModal}>
            <img src={process.env.PUBLIC_URL + "/icons/btnStarIcon.svg"} alt="" />
            Безкоштовний урок
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
