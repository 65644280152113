import "./styles/App.scss";
import Header from "./components/Header";
import Main from "./components/Main";
import Free from "./components/Free";
import About from "./components/About";
import Special from "./components/Special";
import Classes from "./components/Classes";
import Response from "./components/Response";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import Modal from "./components/Modal";

function App() {
  const [isModal, setIsModal] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadBody, setIsLoadBody] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 4000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoadBody(false);
    }, 100);
  }, []);

  const openModal = () => {
    setIsModal(!isModal);
  };

  return (
    <div className="App">
      {isLoad === true ? (
        <div className="loaderBody">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
      {isLoadBody === true ? (
        <></>
      ) : (
        <>
          {isModal && <Modal openModal={openModal}></Modal>}
          <Header openModal={openModal} />
          <Main openModal={openModal} />
          <Free openModal={openModal} />
          <About />
          <Special />
          <Classes />
          <Response />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
