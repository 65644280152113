import axios from "axios";

const baseUrl = "https://api.telegram.org/bot7836495967:AAGmughLOTJV4CXrck36IFH95ZlFPX8Yp-Q/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-1002380902096&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};