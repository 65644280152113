import "../styles/Free.scss";

const Free = ({ openModal }: any) => {
  
  return (
    <section className="free">
      <div className="free__content">
        <div className="free__pic">
          <img src={process.env.PUBLIC_URL + "/pictures/board.png"} alt="" />
          <div className="free__bgs">
            <img src={process.env.PUBLIC_URL + "/bgs/yellowFree.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/dashFree.svg"} alt="" />
          </div>
        </div>
        <div className="free__txt">
          <div className="free__card">
            <div className="free__msg">
              <h3 className="appear">
                Шукаєте ефективний спосіб покращити інтелектуальний розвиток
                <br />
              </h3>
              <h3 className="appear">
                Вашої дитини?
                <img src={process.env.PUBLIC_URL + "/emoji/Monocle.png"} alt="" />
              </h3>
            </div>
            <img src={process.env.PUBLIC_URL + "/clouds/cloud1.svg"} alt="" />
          </div>
          <div className="free__card-two">
            <div className="free__msg-two">
              <h3 className="appear">Спробуйте безкоштовне заняття</h3>
              <h3 className="appear">
                у Quick School
                <img src={process.env.PUBLIC_URL + "/emoji/Winking.png"} alt="" />
              </h3>
            </div>
            <img src={process.env.PUBLIC_URL + "/clouds/cloud2.svg"} alt="" />
          </div>
          <div className="free__card-three">
            <div className="free__msg-three appear">
              <img src={process.env.PUBLIC_URL + "/emoji/StarStruck.png"} alt="" />
              <img src={process.env.PUBLIC_URL + "/emoji/Halo.png"} alt="" />
            </div>
            <img src={process.env.PUBLIC_URL + "/clouds/cloud3.svg"} alt="" />
          </div>
          <button className="btn" onClick={openModal}>
            <img src={process.env.PUBLIC_URL + "/icons/btnStarIcon.svg"} alt="" />
            Безкоштовний урок
          </button>
        </div>
      </div>
    </section>
  );
};

export default Free;