import React from "react";
import "../styles/Classes.scss";

const Classes = () => {
  return (
    <section className="classes" id="classes">
      <img className="classes__bgs-yellow" src={process.env.PUBLIC_URL + "/bgs/classesYell.svg"} alt="" />
      <img className="classes__bgs-dot" src={process.env.PUBLIC_URL + "/bgs/classesDots.svg"} alt="" />
      <div className="classes__content">
        <div className="classes__pic">
          <img className="classes__pic-table" src={process.env.PUBLIC_URL + "/pictures/table.webp"} alt="" />
        </div>
        <div className="classes__list">
          {classesData.map((el, index) => (
            <div className="classes__card" key={index}>
              <div className="classes__img">
                <img src={process.env.PUBLIC_URL + el.img} alt="" />
              </div>
              <div className="classes__info">
                <h3>{el.title}</h3>
                <p>{el.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Classes;

const classesData = [
  {
    id: 0,
    img: "/pictures/calc.PNG",
    title: "Ментальна арифметика",
    desc: "Це метод тренування розуму, спрямований на швидке виконання арифметичних обчислень без використання калькулятора чи паперу. Ментальна арифметика не лише покращує математичні здібності, але й сприяє всебічному розвитку дитини.",
  },
  {
    id: 1,
    img: "/pictures/coffee.png",
    title: "Швидкочитання",
    desc: "Це методика, спрямована на розвиток у дітей вміння читати швидко, зберігаючи при цьому розуміння та засвоєння прочитаного. Цей навик допомагає покращити концентрацію, розширити словниковий запас і розвинути когнітивні здібності.",
  },
  {
    id: 2,
    img: "/pictures/lamp.png",
    title: "Українська мова",
    desc: "Курси української мови навчають граматики, орфографії, читання та аналізу текстів, розвивають мовленнєві навички та творчість. Вони спрямовані на формування любові до рідної мови і культури, а також впевненого користування українською у повсякденному житті.",
  },
  {
    id: 3,
    img: "/pictures/puzzle.png",
    title: "Англійська мова",
    desc: "Наші курси англійської мови створені з урахуванням сучасних методик викладання, що дозволяють дітям швидко опанувати мову та впевнено використовувати її у повсякденному житті.",
  },
  {
    id: 4,
    img: "/pictures/ruler.png",
    title: "Математика",
    desc: "Наші курси математики адаптовані для дітей різного віку та рівня підготовки. Незалежно від того, чи вашій дитині потрібно підтягнути базові знання, чи вона вже готова до поглибленого вивчення математики – у нас є програми, які підійдуть саме їй.",
  },
];
