import React from "react";
import "../styles/Main.scss";

const Main = ({ openModal }: any) => {
  return (
    <section className="main">
      <div className="main__content wrapper">
        <div className="main__info">
          <h1>Quick School — сучасна онлайн-школа для всебічного розвитку дитини</h1>
          <button className="btn" onClick={openModal}>
            Подивитись усі курси
            <img src={process.env.PUBLIC_URL + "/icons/btnArrowIcon.svg"} alt="" />
          </button>
          <div className="main__bgs2">
            <img src={process.env.PUBLIC_URL + "/bgs/crossMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/linesMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/sunMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/riverMain.svg"} alt="" />
          </div>
        </div>
        <div className="main__pic">
          <img className="main__img" src={process.env.PUBLIC_URL + "/pictures/main.webp"} alt="" />
          <div className="main__bgs">
            <img src={process.env.PUBLIC_URL + "/bgs/brownMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/dashMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/circleMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/greenMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/curveMain.svg"} alt="" />
            <img src={process.env.PUBLIC_URL + "/bgs/crossMain.svg"} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
