import React from "react";
import "../styles/Special.scss";

const Special = () => {
  return (
    <section className="special" id="special">
      <img className="special__title" src={process.env.PUBLIC_URL + "/bgs/specialTitle.svg"} alt="" />
      <img className="special__bg" src={process.env.PUBLIC_URL + "/bgs/specialTop.svg"} alt="" />
      <div className="special__content">
        <div className="special__list">
          {cardsData.map((el, index) => (
            <div className="special__card" key={index}>
              <div className="special__card-title appear">
                <img src={process.env.PUBLIC_URL + el.img} alt="" />
                <h3>{el.title}</h3>
              </div>
              <div className="special__card-desc appear">
                <ul>
                  <li>{el.list1}</li>
                  {el.list2 && <li>{el.list2}</li>}
                </ul>
              </div>
              <div className="special__card-bg"></div>
              <img className="special__card-clip" src={process.env.PUBLIC_URL + "/bgs/cardClip.svg"} alt="" />
            </div>
          ))}
        </div>
        <div className="special__img">
          <img className="special__img-guys" src={process.env.PUBLIC_URL + "/pictures/guys.webp"} alt="" />
          <img className="special__img-bg" src={process.env.PUBLIC_URL + "/bgs/specialsYellow.svg"} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Special;

const cardsData = [
  {
    id: 0,
    img: "/pictures/books.png",
    title: "Кваліфіковані викладачі",
    list1:
      "Наші висококваліфіковані викладачі забезпечують індивідуальний підхід до кожної дитини, враховуючи її особливості та потреби в навчанні.",
  },
  {
    id: 1,
    img: "/pictures/pencil.png",
    title: "Індивідуальні та парні заняття",
    list1:
      "Ми пропонуємо різноманітні формати навчання — від індивідуальних занять, що дозволяють максимально адаптувати процес навчання під потреби кожної дитини, до парних занять, де учні можуть обговорювати теми та співпрацювати.",
  },
  {
    id: 2,
    img: "/pictures/abstract.png",
    title: "Власна інтерактивна платформа",
    list1:
      "Quick School оснащена зручною та цікавою платформою, яка дозволяє ефективно вивчати новий матеріал та робити домашні завдання.",
  },
  {
    id: 3,
    img: "/pictures/game.png",
    title: "Гейміфікація для дітей",
    list1: "Щоб зробити навчання захопливим і цікавим, ми інтегрували елементи гейміфікації.",
    list2: "Це стимулює дітей до активного навчання, підвищує мотивацію та робить процес пізнання цікавим і веселим.",
  },
];
