import React from 'react';
import "../styles/About.scss";

const About = () => {
    return (
        <section className='about wrapper' id='about'>
            <div className="about__content appear">
                <h3 className='appear'>Quick School – це ідеальний вибір для батьків, які хочуть, щоб їхні діти отримували якісну освіту в комфортних умовах. Ми допоможемо розкрити потенціал Вашої дитини та підготувати її до успішного майбутнього!</h3>
                <h3 className='appear'>Quick School – місце, де навчання стає легким, цікавим та адаптованим під Ваші потреби. Відкрийте для своєї дитини нові можливості разом з нами! </h3>
            </div>
            <div className="about__logo">
                <img src={process.env.PUBLIC_URL + "logo.svg"} alt="" />
            </div>
        </section>
    );
};

export default About;